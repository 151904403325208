.flex {
  display: flex;
}

/* Flex Direction */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

/* Justify Content */
.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

/* Align Items */
.align-start {
  display: flex;
  align-items: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.align-baseline {
  display: flex;
  align-items: baseline;
}

.align-stretch {
  display: flex;
  align-items: stretch;
}

/* Align Self */
.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

/* Flex Wrap */
.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  display: flex;
  flex-wrap: wrap-reverse;
}

/* Flex Grow, Shrink, and Basis */
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-basis-auto {
  flex-basis: auto;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-1 {
  flex: 1 1 0%;
}

/* Flex Gap */
.gap-0 {
  gap: 0;
}

.gap-xxs {
  gap: 0.25rem; /* 4px */
}

.gap-xs {
  gap: 0.5rem; /* 8px */
}

.gap-s {
  gap: 0.75rem; /* 12px */
}
