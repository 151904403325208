/* Display Classes */
.hidden {
  display: none;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

/* Position Classes */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

/* Overflow Classes */
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Object Fit Classes */
.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

/* Opacity Classes */
.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

/* Border Classes */

.border {
  border: 1px solid var(--color-lines);
}

.border-0 {
  border: 0;
}

.border-t {
  border-top: 1px solid var(--color-lines);
}

.border-r {
  border-right: 1px solid var(--color-lines);
}

.border-b {
  border-bottom: 1px solid var(--color-lines);
}

.border-l {
  border-left: 1px solid var(--color-lines);
}

.border-t-0 {
  border-top: 0;
}

.border-r-0 {
  border-right: 0;
}

.border-b-0 {
  border-bottom: 0;
}

.border-l-0 {
  border-left: 0;
}

/* Border Radius Classes */

.rounded {
  border-radius: var(--border-radius-m);
}

.rounded-xs {
  border-radius: var(--border-radius-xs);
}

.rounded-s {
  border-radius: var(--border-radius-s);
}

.rounded-l {
  border-radius: var(--border-radius-l);
}

.rounded-full {
  border-radius: var(--border-radius-xl);
}

.rounded-t {
  border-top-left-radius: var(--border-radius-m);
  border-top-right-radius: var(--border-radius-m);
}

.rounded-r {
  border-top-right-radius: var(--border-radius-m);
  border-bottom-right-radius: var(--border-radius-m);
}

.rounded-b {
  border-bottom-right-radius: var(--border-radius-m);
  border-bottom-left-radius: var(--border-radius-m);
}

.rounded-l {
  border-top-left-radius: var(--border-radius-m);
  border-bottom-left-radius: var(--border-radius-m);
}

/* Cursor Classes */

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* Aspect Ratio Classes */

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}
