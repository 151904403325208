.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius-m);
  border: 1.5px solid var(--color-lines);
  padding: var(--spacing-m);
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
}

.account {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.statusText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: var(--color-primary);
  padding-top: var(--spacing-xxs);
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: var(--spacing-s);
  }
  .stripeLogo {
    width: var(--spacing-6xl);
    height: var(--spacing-6xl);
  }
}
