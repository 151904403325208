:root {
  /* colors */
  --color-text-primary: #272A34;
  --color-text-secondary: #787E80;
  --color-text-tertiary: #272A34;
  --color-sportsman-brand: #1D72F4;
  --color-sportswoman-brand: #D92C9E;
  --color-success: #05A44F;
  --color-error: #F25330;
  --color-warning: #F98600;
  --color-surface1: #F9FBFC;
  --color-surface2: #F9FBFB;
  --color-blue-surface: #E9F1FE;
  --color-lines: #E9ECEF;

  /* borders */
  --border-radius-xs: 4px;
  --border-radius-s: 8px;
  --border-radius-m: 12px;
  --border-radius-l: 32px;
  --border-radius-xl: 300px;

  /* spacings */
  --spacing-none: 0px;
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-m: 16px;
  --spacing-l: 20px;
  --spacing-xl: 24px;
  --spacing-xxl: 28px;
  --spacing-3xl: 32px;
  --spacing-4xl: 40px;
  --spacing-5xl: 60px;
  --spacing-6xl: 80px;
}

body.theme-sportsman {
  --color-primary: var(--color-sportsman-brand);
}

body.theme-sportswoman {
  --color-primary: var(--color-sportswoman-brand);
}

